import _mergeJSXProps2 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
import _objectWithoutProperties from "D:/workspace/emind-ui-1.0/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { isvalidPhone, isvalidPass, isvalidUsername, isvalidRealname, isvalidEmail } from "../../assets/validate/validate";
import Vue from 'vue';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import moment from 'moment';
import form from 'jquery-form';
var equipTypeList = [];
var columns = [{
  title: '用户名',
  dataIndex: 'loginName',
  width: 140,
  scopedSlots: {
    customRender: '用户名'
  }
}, {
  title: '真实姓名',
  dataIndex: 'name',
  width: 90,
  scopedSlots: {
    customRender: '真实姓名'
  }
}, {
  title: '邮箱',
  dataIndex: 'email',
  width: 150,
  scopedSlots: {
    customRender: '邮箱'
  },
  ellipsis: true
}, {
  title: '电话',
  dataIndex: 'telephone',
  width: 130,
  scopedSlots: {
    customRender: '电话'
  }
}, {
  title: '账号状态',
  dataIndex: 'status',
  width: 90,
  scopedSlots: {
    customRender: '账号状态'
  },
  customRender: function customRender(status) {
    return status == 1 ? '使用中' : status == 0 ? '未激活' : status == 2 ? '停用' : status == 3 ? '禁用' : status == 4 ? '到期' : '未知';
  }
}, {
  title: '告警通知',
  dataIndex: 'alarmNotifierStatus',
  width: 90,
  scopedSlots: {
    customRender: '告警通知'
  },
  customRender: function customRender(alarmNotifierStatus) {
    return alarmNotifierStatus == 1 ? '通知' : '不通知';
  }
}, {
  title: '角色权限',
  dataIndex: 'roles',
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: '角色权限'
  },
  customRender: function customRender(roles) {
    var rolesStr = '';
    roles.map(function (x) {
      rolesStr += x.name + '/';
    });
    rolesStr = rolesStr.substring(0, rolesStr.length - 1);
    return rolesStr;
  }
}, {
  title: '过期时间',
  dataIndex: 'expireTime',
  width: 120,
  scopedSlots: {
    customRender: '过期时间'
  },
  customRender: function customRender(expireTime) {
    return expireTime == null ? '' : expireTime.toString().split(" ")[0];
  }
}, {
  title: '类型',
  dataIndex: 'type',
  width: 90,
  scopedSlots: {
    customRender: '类型'
  },
  customRender: function customRender(type) {
    return type == 1 ? '个人账户' : '集团用户';
  }
}, {
  title: '备注',
  dataIndex: 'remarks',
  width: 140,
  scopedSlots: {
    customRender: '备注'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  width: 90,
  scopedSlots: {
    customRender: 'action'
  }
}];
var draggingMap = {};
columns.forEach(function (col) {
  draggingMap[col.Id] = col.width;
});
var draggingState = Vue.observable(draggingMap);
var ResizeableTitle = {
  functional: true,
  render: function render(h, h1) {
    var props = h1.props;
    var children = h1.children;
    var thDom = null;

    var key = props.key,
        restProps = _objectWithoutProperties(props, ["key"]);

    var col = columns.find(function (col) {
      var k = col.dataIndex || col.key;
      return k === key;
    });

    if (!col || !col.width) {
      return h("th", _mergeJSXProps([{}, restProps]), [children]);
    }

    var onDrag = function onDrag(x, y) {
      draggingState[key] = 0;
      col.width = Math.max(x, 1);
    };

    var onDragstop = function onDragstop() {
      draggingState[key] = thDom.getBoundingClientRect().width;
    };

    return h("th", _mergeJSXProps2([{}, restProps, {
      "directives": [{
        name: "ant-ref",
        value: function value(r) {
          return thDom = r;
        }
      }],
      "attrs": {
        "width": col.width
      },
      "class": "resize-table-th"
    }]), [children, h("vue-draggable-resizable", {
      "key": col.rowKey,
      "class": "table-draggable-handle",
      "attrs": {
        "w": 10,
        "x": draggingState[key] || col.width,
        "z": 1,
        "axis": "x",
        "draggable": true,
        "resizable": false
      },
      "on": {
        "dragging": onDrag,
        "dragstop": onDragstop
      }
    })]);
  }
};
export default {
  data: function data() {
    var _this = this;

    var validTelephone = function validTelephone(rule, value, callback) {
      if (_this.operationType == 3) {
        callback();
      } else {
        if (!value) {
          callback(new Error("电话号码不能为空！"));
        }

        setTimeout(function () {
          if (!isvalidPhone(value)) {
            callback(new Error("电话号码格式错误！"));
          } else {
            callback();
          }
        }, 50);
      }
    };

    var validEmail = function validEmail(rule, value, callback) {
      if (_this.operationType == 3) {
        callback();
      } else {
        if (!value) {
          callback(new Error("邮箱地址不能为空！"));
        }

        setTimeout(function () {
          if (!isvalidEmail(value)) {
            callback(new Error("邮箱地址格式错误！"));
          } else {
            callback();
          }
        }, 50);
      }
    };

    var validPassword = function validPassword(rule, value, callback) {
      if (_this.operationType == 2) {
        callback();
      } else {
        if (!_this.userForm.password) {
          callback(new Error("密码不能为空！"));
        }

        setTimeout(function () {
          if (!isvalidPass(_this.userForm.password)) {
            callback(new Error("密码格式错误，密码中必须包含大小写 字母、数字、特称字符，至少8个字符，最多30个字符！"));
          } else {
            if (_this.userForm.confirmPassword !== "") {
              _this.$refs.userForm.validateField("fallaciousConfirmPassword");
            }

            callback();
          }
        }, 50);
      }
    };

    var validConfirmPassword = function validConfirmPassword(rule, value, callback) {
      if (_this.operationType == 2) {
        callback();
      } else {
        if (!_this.userForm.confirmPassword) {
          callback(new Error("确认密码不能为空！"));
        }

        setTimeout(function () {
          if (_this.userForm.confirmPassword !== _this.userForm.password) {
            callback(new Error("两次输入密码不一致！"));
          } else {
            callback();
          }
        }, 50);
      }
    };

    var validRoles = function validRoles(rule, value, callback) {
      if (_this.operationType == 3) {
        callback();
      } else {
        if (Array.isArray() || value.length < 1) {
          callback(new Error("请为用户选择权限！"));
        } else {
          callback();
        }
      }
    };

    var validType = function validType(type, value, callback) {
      if (_this.operationType == 3) {
        callback();
      } else {
        if (!value) {
          callback(new Error("请为用户选择类型！"));
        } else {
          callback();
        }
      }
    };

    var validequipmentGroupIds = function validequipmentGroupIds(rule, value, callback) {
      if (_this.operationType == 3) {
        callback();
      } else {
        if (Array.isArray() || value.length < 1) {
          callback(new Error("请为用户选择设备组！"));
        } else {
          callback();
        }
      }
    };

    var validStatus = function validStatus(rule, value, callback) {
      callback();
    };

    var validAlarmNotifierStatus = function validAlarmNotifierStatus(rule, value, callback) {
      callback();
    };

    var validUsername = function validUsername(rule, value, callback) {
      if (_this.operationType == 3) {
        return callback();
      } else {
        if (!value) {
          callback(new Error("用户名不能为空！"));
        }

        setTimeout(function () {
          if (!isvalidUsername(value)) {
            callback(new Error("用户名要求数字、字母、下划线的组合 数字和字母必须存在 长度为4-15个字符！"));
          } else {
            callback();
          }
        }, 50);
      }
    };

    var validRealname = function validRealname(rule, value, callback) {
      if (_this.operationType == 3) {
        return callback();
      } else {
        if (!value) {
          callback(new Error("真实姓名不能为空！"));
        }

        setTimeout(function () {
          if (!isvalidRealname(value)) {
            callback(new Error("真实姓名不正确！"));
          } else {
            callback();
          }
        }, 50);
      }
    };

    var validExpireTime = function validExpireTime(rule, value, callback) {
      if (_this.operationType == 3) {
        callback();
      } else {
        if (!value) {
          callback(new Error("过期时间不能为空"));
        } else {
          callback();
        }
      }
    };

    var validRemarks = function validRemarks(rule, value, callback) {
      callback();
    };

    this.components = {
      header: {
        cell: ResizeableTitle
      }
    };
    return {
      emailSuffix: ".com",
      detialTitle: '',
      placement: 'right',
      wrapperCol: {
        span: 13
      },
      labelCol: {
        span: 6
      },
      selectedRows: [],
      selectedRowKeys: [],
      pagination: {
        defaultPageSize: 50,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.pageSize = pageSize;
        }
      },
      columns: columns,
      dateFormat: 'YYYY-MM-DD',
      datePickerLocale: datePickerLocale,
      scroll: {
        y: 660
      },
      roles: [],
      confirmPasswordShow: false,
      passwordShow: false,
      userForm: {
        username: '',
        realname: '',
        fallaciousConfirmPassword: '',
        fallaciousPassword: '',
        password: '',
        confirmPassword: '',
        email: '',
        telephone: '',
        status: 1,
        type: 1,
        alarmNotifierStatus: 0,
        roles: [],
        equipmentGroupIds: [],
        expireTime: moment().subtract(1, 'year'),
        remarks: "",
        id: null
      },
      userTypes: [{
        id: 1,
        name: '个人用户'
      }, {
        id: 2,
        name: '集团用户'
      }],
      rules: {
        username: [{
          validator: validUsername,
          trigger: 'blur'
        }],
        realname: [{
          validator: validRealname,
          trigger: 'blur'
        }],
        fallaciousPassword: [{
          validator: validPassword,
          trigger: 'blur'
        }],
        fallaciousConfirmPassword: [{
          validator: validConfirmPassword,
          trigger: 'blur'
        }],
        email: [{
          validator: validEmail,
          trigger: 'blur'
        }],
        telephone: [{
          validator: validTelephone,
          trigger: 'blur'
        }],
        type: [{
          validator: validType,
          trigger: 'blur'
        }],
        roles: [{
          validator: validRoles,
          trigger: 'blur'
        }],
        equipmentGroupIds: [{
          validator: validequipmentGroupIds,
          trigger: 'blur'
        }],
        status: [{
          validator: validStatus,
          trigger: 'blur'
        }],
        alarmNotifierStatus: [{
          validator: validAlarmNotifierStatus,
          trigger: 'blur'
        }],
        remarks: [{
          validator: validRemarks,
          trigger: 'blur'
        }],
        expireTime: [{
          validator: validExpireTime,
          trigger: 'blur'
        }]
      },
      equipmentGroups: [],
      loginUserId: -1,
      keyboard: false,
      search: {
        param3: null,
        param1: '-1',
        length: 1000000000,
        page: 1,
        startIndex: 0
      },
      tableData3: [],
      total: 0,
      showTotal: true,
      userFormShow: false,
      dialogTitle: '添加设备',
      operationType: 0,
      loading: false
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.loginUserId = this_.$store.state.userId;
    this_.listRole();
    this_.listEquipmentGroups();
    this_.getList();
  },
  computed: {
    rowSelection: function rowSelection() {
      var _this2 = this;

      return {
        //onChange方法，rowSelection改变时触发
        onChange: function onChange(selectedRowKeys, list) {
          //获取选中行的key元素，和下面selectedRowKeys属性配合使用，表示给rowSelection绑定一个v-model，为this.selectedRowKeys
          _this2.selectedRowKeys = selectedRowKeys; //获取选中的行的所有元素

          _this2.selectedRows = list;
        },
        selectedRowKeys: this.selectedRowKeys
      };
    }
  },
  methods: {
    clearRowSelection: function clearRowSelection() {
      //将选中行的所有元素置空
      this.selectedRows = []; //将选中行的keys置空（即清空选中状态）

      this.selectedRowKeys = [];
    },
    resetPassqord: function resetPassqord(row) {
      var this_ = this;
      var data = {
        id: row.id,
        password: 'Test_123'
      };
      axios({
        method: 'put',
        url: '/emind/user/users/password',
        data: data
      }).then(function (d) {
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.status === "2000") {
          this_.$message.success('用户：' + row.loginName + ' 重置密码操作成功！密码为：Test_123');
          this_.operationType = 0;
          this_.FormVisible = false;
        } else {
          this_.$message.warning('重置密码操作失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    mousedown: function mousedown(e) {
      e.preventDefault();
    },
    keyDown: function keyDown(e) {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowRight') {
        e.preventDefault();
      }
    },
    moveEnd: function moveEnd(id) {
      var obj = document.getElementById(id);
      obj.focus();
      var len = obj.value.length;

      if (document.selection) {
        var sel = obj.createTextRange();
        sel.moveStart('character', len);
        sel.collapse();
        sel.select();
      } else if (typeof obj.selectionStart == 'number' && typeof obj.selectionEnd == 'number') {
        obj.selectionStart = obj.selectionEnd = len;
      }
    },
    change: function change(evn, type) {
      var this_ = this;

      if (evn.inputType === 'deleteContentBackward') {
        if (type == 1) {
          if (this.userForm.fallaciousPassword.length == 0) {
            this.userForm.password = '';
          } else if (this.userForm.fallaciousPassword.length == this.userForm.password.length - 1) {
            this.userForm.password = this.userForm.password.substring(0, this.userForm.password.length - 1);

            if (!this.passwordShow) {
              this.userForm.fallaciousPassword = this.userForm.password.replace(/./g, '*');
            } else {
              this.userForm.fallaciousPassword = this.userForm.password;
            }
          }
        } else if (type == 2) {
          if (this.userForm.fallaciousConfirmPassword.length == 0) {
            this.userForm.confirmPassword = '';
          } else if (this.userForm.fallaciousConfirmPassword.length == this.userForm.confirmPassword.length - 1) {
            this.userForm.confirmPassword = this.userForm.confirmPassword.substring(0, this.userForm.confirmPassword.length - 1);
            this.userForm.fallaciousConfirmPassword = this.userForm.confirmPassword.replace(/./g, '*');

            if (!this.confirmPasswordShow) {
              this.userForm.fallaciousConfirmPassword = this.userForm.confirmPassword.replace(/./g, '*');
            } else {
              this.userForm.fallaciousConfirmPassword = this.userForm.confirmPassword;
            }
          }
        }
      } else {
        if (type == 1) {
          if (!this.passwordShow) {
            this.userForm.password = this.userForm.password + this.userForm.fallaciousPassword.replace(/\*/g, "");
            this.userForm.fallaciousPassword = this.userForm.password.replace(/./g, '*');
          } else {
            this.userForm.password = this.userForm.fallaciousPassword;
          }
        } else if (type == 2) {
          if (!this.confirmPasswordShow) {
            this.userForm.confirmPassword = this.userForm.confirmPassword + this.userForm.fallaciousConfirmPassword.replace(/\*/g, "");
            this.userForm.fallaciousConfirmPassword = this.userForm.confirmPassword.replace(/./g, '*');
          } else {
            this.userForm.confirmPassword = this.userForm.fallaciousConfirmPassword;
          }
        }
      }
    },
    showPassword: function showPassword() {
      this.passwordShow = !this.passwordShow;
      this.userForm.fallaciousPassword = this.userForm.password;
    },
    hidePassword: function hidePassword() {
      this.passwordShow = !this.passwordShow;
      this.userForm.fallaciousPassword = this.userForm.password.replace(/./g, '*');
    },
    showConfirmPassword: function showConfirmPassword() {
      this.confirmPasswordShow = !this.confirmPasswordShow;
      this.userForm.fallaciousConfirmPassword = this.userForm.confirmPassword;
    },
    hideConfirmPassword: function hideConfirmPassword() {
      this.confirmPasswordShow = !this.confirmPasswordShow;
      this.userForm.fallaciousConfirmPassword = this.userForm.confirmPassword.replace(/./g, '*');
    },
    beforeCloseHandel: function beforeCloseHandel() {
      var this_ = this;
      this_.operationType = 0;
      this_.userFormShow = false;
      this_.userForm = {
        username: '',
        realname: '',
        fallaciousConfirmPassword: '',
        fallaciousPassword: '',
        password: '',
        confirmPassword: '',
        email: '',
        telephone: '',
        status: 1,
        type: 1,
        alarmNotifierStatus: 0,
        roles: [],
        equipmentGroupIds: [],
        expireTime: moment().subtract(1, 'year'),
        remarks: "",
        id: null
      };
    },
    listRole: function listRole() {
      var this_ = this;
      axios({
        method: "get",
        url: "/emind/user/roles",
        params: {
          page: 1,
          pageSize: 10000
        }
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.status == 2000) {
          this_.roles = d.data.data.data;
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    submitForm: function submitForm(formName) {
      var this_ = this;
      this_.$refs[formName].validate(function (valid) {
        if (valid) {
          this_.okFn();
        }
      });
    },
    editPasswd: function editPasswd() {
      var this_ = this;

      if (this_.operationType != 0) {
        return;
      }

      this_.dialogTitle = '修改密码';
      this_.operationType = 3;
      this_.userFormShow = true;
    },
    okFn: function okFn() {
      var _this3 = this;

      var this_ = this;
      var user;

      if (this_.operationType < 3) {
        user = {
          'loginName': this_.userForm.username,
          'name': this_.userForm.realname,
          'password': this_.userForm.password,
          'email': this_.userForm.email + this_.emailSuffix,
          'telephone': this_.userForm.telephone,
          'createTime': new Date().Format("yyyy-MM-dd hh:mm:ss"),
          'status': this_.userForm.status,
          'alarmNotifierStatus': parseInt(this_.userForm.alarmNotifierStatus),
          'roles': this_.userForm.roles,
          'type': this_.userForm.type,
          'equipmentGroupIds': this_.userForm.equipmentGroupIds,
          'expireTime': this_.userForm.expireTime.format("YYYY-MM-DD hh:mm:ss"),
          'remarks': this_.userForm.remarks,
          'id': this_.userForm.id
        };
      }

      if (this_.operationType == 1) {
        axios({
          method: 'post',
          url: '/emind/user/users/user',
          data: user
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.status === "2000") {
            this_.$message.success('用户添加成功');
            this_.operationType = 0;
            this_.userFormShow = false;
            this_.userForm = {
              username: '',
              realname: '',
              fallaciousConfirmPassword: '',
              fallaciousPassword: '',
              password: '',
              confirmPassword: '',
              email: '',
              telephone: '',
              status: 1,
              type: 1,
              alarmNotifierStatus: 0,
              roles: [],
              equipmentGroupIds: [],
              expireTime: moment().subtract(1, 'year'),
              remarks: "",
              id: null
            };
          } else {
            _this3.$message.warning('用户添加失败');
          }

          setTimeout(function () {
            this_.getList();
          }, 200);
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else if (this_.operationType == 2) {
        var data = {
          password: user.password
        };
        axios({
          method: 'put',
          url: '/emind/user/users/user',
          data: user
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.status === "2000") {
            this_.$message.success('用户更新成功');
            this_.operationType = 0;
            this_.userFormShow = false;
            this_.clearRowSelection();
            this_.userForm = {
              username: '',
              realname: '',
              fallaciousConfirmPassword: '',
              fallaciousPassword: '',
              password: '',
              confirmPassword: '',
              email: '',
              telephone: '',
              status: 1,
              type: 1,
              alarmNotifierStatus: 0,
              roles: [],
              equipmentGroupIds: [],
              expireTime: moment().subtract(1, 'year'),
              remarks: "",
              id: null
            };
          } else {
            _this3.$message.warning('用户更新失败');
          }

          setTimeout(function () {
            this_.getList();
          }, 200);
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else {
        user = {
          id: this_.userForm.id,
          password: this_.userForm.confirmPassword
        };
        axios({
          method: 'put',
          url: '/emind/user/users/password',
          data: user
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.status === "2000") {
            this_.$message.success('修改密码操作成功，请重新登录！');
            this_.userForm.password = null;
            this_.userForm.fallaciousConfirmPassword = '', this_.userForm.fallaciousPassword = '', this_.userForm.password = '', this_.userForm.confirmPassword = '', this_.userForm.id = null;
            this_.operationType = 0;
            this_.userFormShow = false;
            this_.$store.dispatch("users/loginOUt", {
              router: this_.$router
            });
          } else {
            _this3.$message.warning('修改密码操作失败');
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      }
    },
    add: function add() {
      var this_ = this;

      if (this_.operationType != 0) {
        return;
      }

      this_.listRole();
      this_.listEquipmentGroups();
      this_.operationType = 1;
      this_.dialogTitle = '新建用户';
      this_.userFormShow = true;
      this_.userForm = {
        username: '',
        realname: '',
        fallaciousConfirmPassword: '',
        fallaciousPassword: '',
        password: '',
        confirmPassword: '',
        email: '',
        telephone: '',
        status: 1,
        type: 1,
        alarmNotifierStatus: 0,
        roles: [],
        equipmentGroupIds: [],
        expireTime: moment().subtract(1, 'year'),
        remarks: "",
        id: null
      };
    },
    edit: function edit() {
      var this_ = this;

      if (this_.operationType != 0) {
        return;
      }

      this_.dialogTitle = '更新用户信息';

      if (this_.selectedRows.length != 1) {
        this.$message.warning('您还没有选择要更新的用户，请先选择！');
        return;
      }

      var index = this_.selectedRows[0].email.lastIndexOf('.');
      this_.emailSuffix = this_.selectedRows[0].email.substring(index);
      this_.userForm = {
        username: this_.selectedRows[0].loginName,
        realname: this_.selectedRows[0].name,
        email: this_.selectedRows[0].email.substring(0, index),
        telephone: this_.selectedRows[0].telephone,
        status: this_.selectedRows[0].status,
        type: this_.selectedRows[0].type,
        alarmNotifierStatus: this_.selectedRows[0].alarmNotifierStatus,
        roles: [],
        equipmentGroupIds: [],
        expireTime: moment(this_.selectedRows[0].expireTime, "YYYY-MM-DD HH:mm:ss"),
        remarks: this_.selectedRows[0].remarks,
        id: this_.selectedRows[0].id
      };
      this_.userForm.alarmNotifierStatus = this_.userForm.alarmNotifierStatus ? 1 : 0;

      for (var i = 0; i < this_.selectedRows[0].roles.length; i++) {
        this_.userForm.roles.push(this_.selectedRows[0].roles[i].id);
      }

      this_.userForm.equipmentGroupIds = this_.selectedRows[0].equipmentGroupIds;
      this_.operationType = 2;
      this_.dialogTitle = '更新用户';
      this_.userFormShow = true;
    },
    del: function del() {
      var this_ = this;
      var ids = [];

      if (this_.selectedRows.length < 1) {
        this.$message.warning('您还没有选择要删除的用户，请先选择！');
        return;
      } else if (this_.selectedRows.length < 2) {
        ids.push(this_.selectedRows[0].id);
      } else {
        this_.selectedRows.map(function (x) {
          ids.push(x.id);
        });
      }

      this_.$confirm({
        title: "删除设备",
        content: '此操作将永久删除选中的用户, 是否继续?',
        okText: '确认',
        cancelText: '取消',
        onOk: function onOk() {
          axios({
            method: 'delete',
            url: '/emind/user/users',
            data: ids
          }).then(function (d) {
            //
            if (!d) {
              this_.$message.error('登录超时，请重新登录', 2, function () {});
              return;
            }

            if (d.data.status === "2000") {
              this_.$message.success('用户删除成功');
              setTimeout(function () {
                this_.getList();
              }, 100);
            } else {
              this_.$message.error('请求出错', 2, function () {});
            }

            this_.clearRowSelection();
          }).catch(function (error) {
            if (error.message != '') {
              this_.$message.error(error.message, 2, function () {});
              return;
            }
          });
        }
      });
    },
    listEquipmentGroups: function listEquipmentGroups() {
      var this_ = this;
      axios.get('/emind/config/equipment/group/list').then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipmentGroups = d.data.data; //						this_.equipmentGroupsMap.clear();
          //						this_.equipmentGroups.map(function(x) {
          //							this_.equipmentGroupsMap.set(x.id, x);
          //						});
        } else {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    //获取刷新列表数据
    getList: function getList() {
      var this_ = this;
      this_.loading = true;
      axios({
        method: "get",
        url: "/emind/user/users",
        params: {
          page: this_.search.page,
          pageSize: this_.search.length,
          name: this_.search.param3,
          roleId: this_.search.param1,
          status: 1,
          type: 1
        }
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.status == 2000) {
          this_.total = d.data.data.totalCount;
          this_.tableData3 = d.data.data.data;

          for (var i = 0; i < this_.tableData3.length; i++) {
            this_.tableData3[i].key = i;
          }
        }

        this_.loading = false;
      }).catch(function (error) {
        this_.loading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.getList();
    }
  }
};